import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useRef } from "react";
import log from "../resources/Logo.svg";
import hambergar from "../resources/hambarger.svg";
import cross from "../resources/cross.svg";
import card from "../resources/ccard.svg";
import "./CreditForm2.css";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function CreditCardForm2() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [toogle, settoggle] = useState(false);

  const navigate = useNavigate();
  const navigateTo = (path) => {
    navigate(path);
  };

  const contactFormRef = useRef(null);

  // Function to scroll to the contact form
  const scrollToContactForm = () => {
    if (contactFormRef.current) {
      contactFormRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleNextButtonClick = () => {
    const form = document.querySelector("form");
    if (form.checkValidity()) {
      const car_insurance_with_variables = {
        ...CreditCard2,
        iddupd: iddupd,
      };

      const serializedObj = encodeURIComponent(
        JSON.stringify(car_insurance_with_variables)
      );

      // Proceed to the next page
      navigateTo(
        `/CreditCardForm1/CreditCardForm2/CreditCardForm3/${serializedObj}`
      );
    } else {
      // Show validation errors
      form.reportValidity();
    }
  };

  const [selectYes, setSelectYes] = useState("Null");

  const handleRadioClick = (value) => {
    setSelectYes(value);
    if (value === "Yes") {
      setCreditCard2({
        ...CreditCard2,
        Have_Any_Vehicle: value,
      });
    } else {
      setCreditCard2({
        ...CreditCard2,
        Have_Any_Vehicle: value,
        Vehicle_Type: null,
        Registration_No: null,
      });
    }
  };

  const { obj } = useParams();
  const obj1 = JSON.parse(decodeURIComponent(obj));

  const [CreditCard2, setCreditCard2] = useState({
    Have_Any_Vehicle: "",
    Vehicle_Type: "",
    Registration_No: "",
  });
  const iddupd = obj1.id;
  const tableName = "credit_card_insurance";

  const addValues = (e) => {
    setCreditCard2({ ...CreditCard2, [e.target.name]: e.target.value });
  };
  console.log(CreditCard2);

  const handlesubmit2 = async (e) => {
    e.preventDefault();
    console.log("data is:", CreditCard2);
    if (selectYes !== null) {
      try {
        const response = await fetch(
          `https://policydekhoo-backend.onrender.com/Updatedata?tableName=${tableName}&id=${iddupd}`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(CreditCard2),
          }
        );

        if (response.ok) {
          console.log("Data updated successfully");
          navigateTo("/CreditCardForm1/CreditCardForm2/CreditCardForm1");
        } else {
          console.error("Failed to update data");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
    }
  };

  const adddetails = () => {
    setSelectYes("Yes");
    handleClose();
  };

  return (
    <div>
      {toogle === false ? (
        <>
          <div className="container-fluid mb-5 ">
            <div className="row ">
              <div
                className="col-lg-4 mt-3 col-10 ps-5   text-start"
                onClick={() => {
                  navigateTo("/");
                }}
              >
                <img
                  src={log}
                  alt=""
                  className=""
                  style={{ width: "4vw", cursor: "pointer" }}
                />
                <span
                  className="fs-3 p-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigateTo("/");
                  }}
                >
                  Policy Dekhoo
                </span>
              </div>

              <div className="col-lg-8 col-2 pe-5  d-flex justify-content-end ">
                <div className="row mt-3 ">
                  <div className="col-12  d-none d-lg-block">
                    <ul className="list-inline">
                      <li
                        className="list-inline-item p-2  fs-6"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigateTo("/");
                        }}
                      >
                        Home
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        PD Advantages
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        Customer Rating
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        About Us
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                        onClick={scrollToContactForm}
                      >
                        Support
                      </li>
                      <li
                        className="list-inline-item p-2 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        Claim
                      </li>
                      <li
                        className="list-inline-item get fs-6 p-2"
                        style={{ cursor: "pointer" }}
                        onClick={scrollToContactForm}
                      >
                        Contact Us
                      </li>
                    </ul>
                  </div>
                  <div className="col d-lg-none">
                    <img
                      src={hambergar}
                      alt=""
                      onClick={(e) => settoggle(!toogle)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {toogle === true ? (
        <>
          <div className="container">
            <div className="row mt-5">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-8  text-start ps-5">
                    <span className="fs-3">Policy dekhoo</span>
                  </div>
                  <div className="col-4">
                    <img
                      src={cross}
                      alt=""
                      onClick={(e) => settoggle(!toogle)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-7 col-1">
                <ul
                  className=""
                  style={{ listStyle: "none", textAlign: "left" }}
                >
                  <li
                    className=" p-2  fs-6"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigateTo("/");
                    }}
                  >
                    Home
                  </li>
                  <li className=" p-2 fs-6" style={{ cursor: "pointer" }}>
                    PD Advantages
                  </li>
                  <li className="p-2 fs-6" style={{ cursor: "pointer" }}>
                    Customer Rating
                  </li>
                  <li className=" p-2 fs-6" style={{ cursor: "pointer" }}>
                    About Us
                  </li>
                  <li
                    className=" p-2 fs-6"
                    style={{ cursor: "pointer" }}
                    onClick={scrollToContactForm}
                  >
                    Support
                  </li>
                  <li className="p-2 fs-6" style={{ cursor: "pointer" }}>
                    Claim
                  </li>
                </ul>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-12">
                <span className="get p-2" onClick={scrollToContactForm}>
                  Contact Us
                </span>
              </div>
            </div>

            <div className="row fixed-bottom">
              <div className="col-12">
                <div className=" ">
                  <span>© 2020 Policy Dekhoo | Powered by Policy Dekhoo</span>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {toogle === false ? (
        <>
          <div className="container-fluid ">
            <div className="row text-start     p-3  ">
              <div className="col ps-lg-5">
                <span
                  className=""
                  onClick={() => {
                    navigateTo("/");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Home&#62;
                </span>
                <span
                  className=""
                  onClick={() => {
                    navigateTo("/CreditCardForm1");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Credit Card&#62;
                </span>
                <span style={{ cursor: "pointer", fontWeight: "500" }}>
                  Vehicle Information
                </span>
              </div>
            </div>
            <div className="row">
              <div className=" col-lg-7   p-3 ">
                <div className="row ps-lg-5">
                  <div className="col-md-4 col-4 ">
                    <hr
                      className="w-100"
                      style={{
                        height: "2px",
                        // width: "10vw",
                        color: "#2D75E0",
                        border: "",
                      }}
                    />
                  </div>
                  <div className="col-md-4 col-4 ">
                    <hr style={{ height: "2px" }} className="w-100" />
                  </div>
                  <div className="col-md-4 col-4">
                    <hr style={{ height: "2px" }} className="w-100" />
                  </div>
                </div>
                <div className="">
                  <p className="fs-3 ps-lg-5" style={{ textAlign: "left" }}>
                    Secure Your Finances with Credit Card Tailored to
                    <span className="fw-bold"> your requirements.</span>
                  </p>
                </div>

                <div className="container">
                  <form action="">
                    <div
                      className="row mt-5 mb-3 ps-md-5 needs-validation"
                      novalidate
                    >
                      <div className=" col-lg-6  text-start">
                        <div>
                          <label htmlFor="" className="mb-3 fw-bold">
                            Do You Have Any Vehicle
                          </label>
                        </div>
                      </div>

                      <div className=" col-lg-6   text-start">
                        <div>
                          <input
                            type="radio"
                            id=""
                            name=""
                            value=""
                            className=""
                            required
                            checked={selectYes === "Yes"}
                            onClick={() => handleRadioClick("Yes")}
                          />
                          <label for="" className="me-5 ps-3">
                            Yes
                          </label>

                          <input
                            type="radio"
                            id=""
                            name=""
                            value=""
                            checked={selectYes === "No"}
                            onClick={() => handleRadioClick("No")}
                            required
                          />
                          <label for="" className="ps-3">
                            No
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row mb-5 mt-4 ps-md-5">
                      <div className="col-md-12  ms-0 text-start">
                        <div>
                          <label htmlFor="" className="mb-3 fw-bold">
                            Vehicle Type
                          </label>
                        </div>
                        <div>
                          <select
                            className="form-select  p-3"
                            required
                            disabled={selectYes !== "Yes"}
                            name="Vehicle_Type"
                            value={CreditCard2.Vehicle_Type}
                            onChange={(e) => {
                              addValues(e);
                            }}
                          >
                            <option value="">Select Vehicle Type</option>
                            <option value="Two Wheeler">Two Wheeler</option>
                            <option value="Four Wheeler">Four Wheeler</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="row ps-md-5">
                      <div className="col-md-12 ">
                        <div className="text-start">
                          <label htmlFor="" className="fw-bold mb-3">
                            Registration No
                          </label>
                          <input
                            type="text"
                            className="form-control p-3"
                            name="Registration_No"
                            value={CreditCard2.Registration_No}
                            onChange={(e) => {
                              addValues(e);
                            }}
                            placeholder="Enter Registration No"
                            required
                            disabled={selectYes !== "Yes"}
                          />
                        </div>
                      </div>
                    </div>

                    <button
                      className="p-2 mt-5 but24 w-25 fs-5"
                      onClick={(e) => {
                        e.preventDefault();
                        if (selectYes === "No") {
                          handleOpen();
                        } else {
                          handlesubmit2(e);
                        }
                      }}
                    >
                      Next
                    </button>
                    {/* modal */}
                    <Modal
                      open={open}
                      onClose={() => setOpen(false)}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                          If you provide detailed information about your
                          vehicle, it may increase your chances of approval for
                          a higher credit limit.
                        </Typography>
                        <Button
                          onClick={adddetails}
                          variant="contained"
                          sx={{ mt: 2, mr: 2 }}
                        >
                          Add Details
                        </Button>
                        <Button
                          onClick={(e) => {
                            handleNextButtonClick(e);
                          }}
                          variant="outlined"
                          sx={{ mt: 2 }}
                        >
                          Skip
                        </Button>
                      </Box>
                    </Modal>
                  </form>
                </div>
              </div>
              <div className="col-lg-4  d-none d-lg-block">
                <div className="position-relative mt-5 py-5">
                  <img src={card} alt="" className="" />
                </div>
              </div>
            </div>
          </div>

          {/* HPI */}
          <div className="container-fluid">
            <div className="row  mx-5">
              <div className="col-md-12   text-start p-3 mt-5">
                <h2>Credit Card Plan</h2>
              </div>
              <div className="row ">
                <div className="col-lg-12 ">
                  <div className="text-start ">
                    <p className="fs-4">
                      Welcome to our Credit Card section! Discover a world of
                      financial freedom and convenience with our range of credit
                      card offerings. Whether you're looking for travel rewards,
                      cash back incentives, or low APR rates, we have the
                      perfect card to suit your lifestyle and financial goals.
                      With easy online application processes and competitive
                      benefits, managing your expenses has never been more
                      rewarding.
                    </p>
                  </div>

                  <div className="text-start">
                    <p className="fs-4">
                      Enjoy perks such as exclusive discounts, fraud protection,
                      and 24/7 customer support. Explore our diverse selection
                      of credit cards today and take the first step towards
                      smarter spending and greater financial flexibility.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* 
      <div className="container-fluid">
        <div className="row px-5 mb-5">
          <div className="col-md-12">
            <h5 style={{ textAlign: "justify" }} className="fs-1 ">
              FAQ
            </h5>
          </div>
        </div> */}
          {/* <div className="row px-5 ">
          <div className="col-lg-8 ">
            <div className="row ">
              <div className="col-md-12">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Why do I need insurance?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      How is insurance premium calculated?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      What is a waiting period in insurance?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      How do I file an insurance claim?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Can I customize my insurance coverage?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>

          <div className="col-4  d-none d-lg-block">
            <div>
              <img src={question} alt="" />
            </div>
          </div>
        </div>
      </div> */}

          {/* Footer */}

          <footer>
            <div className="container-fluid background-imag1 mt-5 ">
              <div className="row p-5">
                <div className="col-sm-6 order-2 order-sm-1 text-start">
                  <div className="row ps-5">
                    <div className="col-lg-6 ">
                      <ul className="list-unstyled text-start list">
                        <li
                          className="mb-3 fw-bold fs-5"
                          onClick={() => {
                            navigateTo("/");
                          }}
                        >
                          Home{" "}
                        </li>
                        <li className="mb-3 fw-bold fs-5">PD Advantages</li>
                        <li className="mb-3 fw-bold fs-5">Customer Rating</li>
                        <li className="mb-3 fw-bold fs-5">About Us</li>
                      </ul>
                    </div>

                    <div className="col-lg-6">
                      <ul className="list-unstyled text-start list">
                        <li className="mb-3 fw-bold fs-5">Support</li>
                        <li className="mb-3 fw-bold fs-5">Claim</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-md-6 p-5 text-sm-end text-center order-1 ">
                  <img
                    src={log}
                    alt=""
                    style={{ width: "5vw" }}
                    className="logo"
                  />
                </div>
              </div>
            </div>
          </footer>

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <h5 className="fs-6">
                  &#169;2020 Policy Dekhoo | Developed By{" "}
                  <a
                    href="https://www.unairesync.com/"
                    className="link"
                    target="blank"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Unaire Sync
                  </a>
                </h5>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default CreditCardForm2;
